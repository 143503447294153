import React, { Component } from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../layouts/layout.js'
import styles from './blog-post.module.scss'
import Media from '../components/media.js'
import Transitioner from '../components/transition'
import Navigation from '../components/navigation'
import arrowEmpty from '../assets/images/cursorThickerTopRight.svg'
import arrowFull from '../assets/images/cursorFilled.svg'
import { InView } from 'react-intersection-observer'
import TransitionLink from 'gatsby-plugin-transition-link'

class BlogPostTemplate extends Component {
  constructor() {
    super()
    this.state = {
      mainPage: '',
      leftLink: '',
      rightLink: '',
      arrowToggle: false,
    }
  }

  componentDidMount() {
    // create promise for graphQl query

    new Promise((resolve, reject) => {
      resolve(get(this.props, 'data.allContentfulBlogPost'))
      reject('error')
    }).then(
      data => {
        const currentLocation = this.props.location.pathname

        // Save to state

        // Left & Right Arrow Nav

        const linksArr = data.edges.map(ele => {
          return '/project/' + ele.node.slug + '/'
        })

        // find index of current slug based on location

        const index = linksArr.indexOf(currentLocation)

        let leftLink = null
        let rightLink = null

        if (index === 0) {
          leftLink = linksArr.length - 1
          rightLink = 1
        } else if (index === linksArr.length - 1) {
          leftLink = linksArr.length - 2
          rightLink = 0
        } else {
          leftLink = index - 1
          rightLink = index + 1
        }

        this.setState({
          leftLink: linksArr[leftLink],
          rightLink: linksArr[rightLink],
        })
      },
      err => {
        console.log(err)
      }
    )
  }

  componentWillUnmount() {
    this.setState({
      mainPage: true,
    })
  }

  fadeIn = () => {
    const arrows = document.getElementById('projectArrows')

    arrows.classList.remove('fadeOut')
    arrows.classList.add('fadeIn')
    arrows.style.display = 'flex'
  }

  fadeOut = () => {
    const arrows = document.getElementById('projectArrows')

    arrows.classList.remove('fadeIn')
    arrows.classList.add('fadeOut')

    setTimeout(function() {
      arrows.style.display = 'none'
    }, 300)
  }

  render() {
    const post = get(this.props, 'data.contentfulBlogPost')

    // Bottom Preview

    // Grab all the images
    // Filter out the current location
    // Take the first 3 images for latest posts

    const preview = get(this.props, 'data.allContentfulBlogPost.edges')

    const imgArr = preview
      .filter(ele => {
        return (
          '/project/' + ele.node.slug + '/' !== this.props.location.pathname
        )
      })
      .slice(0, 3)

    return (
      <div className="background">
        <Transitioner>
          <Layout>
            <Helmet>
              <title>{`Artefact Creative | ${post.title}`}</title>
            </Helmet>

            <Navigation mainPage={this.state.mainPage} />

            <div className={styles.wrapper}>
              <InView
                as="section"
                className={styles.upperBlog}
                threshold="0.5"
                onChange={(inView, entry) => {
                  inView ? this.fadeOut() : this.fadeIn()
                }}
              >
                <div className={styles.leftBlog}>
                  <h1 className={styles.title}>{post.title}</h1>
                </div>

                <div className={styles.rightBlog}>
                  <h3>Description</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html,
                    }}
                  />
                </div>
              </InView>

              <section className={styles.lowerBlog}>
                <ul className={styles.blogList}>
                  
                  {/* {post.blogImage ? (
                    <li>
                      <Media
                        videoCheck={post.blogImage.file.url.slice(-3)}
                        videoSrcURL={post.blogImage.file.url}
                        styleClass={post.blogImage.title}
                        imgSizes={post.blogImage.sizes}
                      />
                    </li>
                  ) : null}

                  <li>
                    <Media
                      videoCheck={post.heroImage.file.url.slice(-3)}
                      videoSrcURL={post.heroImage.file.url}
                      styleClass={post.heroImage.title}
                      imgSizes={post.heroImage.sizes}
                    />
                  </li>

                  {post.imageLeft ? (
                    <li>
                      <Media
                        videoCheck={post.imageLeft.file.url.slice(-3)}
                        videoSrcURL={post.imageLeft.file.url}
                        styleClass={post.imageLeft.title}
                        imgSizes={post.imageLeft.sizes}
                      />
                    </li>
                  ) : null}

                  {post.imageRight ? (
                    <li>
                      <Media
                        videoCheck={post.imageRight.file.url.slice(-3)}
                        videoSrcURL={post.imageRight.file.url}
                        styleClass={post.imageRight.title}
                        imgSizes={post.imageRight.sizes}
                      />
                    </li>
                  ) : null} */}

                  {post.extraImages
                    ? post.extraImages.map(img => {
                        return (
                          <li key={img.title}>
                            <Media
                              videoCheck={img.file.url.slice(-3)}
                              videoSrcURL={img.file.url}
                              styleClass={img.title}
                              imgSizes={img.sizes}
                            />
                          </li>
                        )
                      })
                    : null}
                </ul>
              </section>

              <InView
                as="section"
                threshold="0.2"
                onChange={(inView, entry) => {
                  if (this.state.arrowToggle) {
                    inView ? this.fadeOut() : this.fadeIn()
                  }

                  this.setState({
                    arrowToggle: true,
                  })
                }}
                className={`projectPreviewContainer ${styles.projectPreview}`}
              >
                <h2>Latest Projects</h2>
                <ul className={styles.projectPreviewList}>
                  {imgArr.map(img => {
                    return (
                      <li key={img.node.title}>
                        <TransitionLink
                          to={`/project/${img.node.slug}/`}
                          exit={{ length: 0.6 }}
                          entry={{ delay: 0.8, length: 1 }}
                        >
                          <div className={styles.previewImageContainer}>
                            <Media
                              videoCheck={img.node.heroImage.file.url.slice(-4)}
                              videoSrcURL={img.node.heroImage.file.url}
                              styleClass={img.node.heroImage.title}
                              imgSizes={img.node.heroImage.sizes}
                            />
                          </div>
                          <div className={styles.previewImageText}>
                            <h3>{img.node.title}</h3>
                          </div>
                        </TransitionLink>
                      </li>
                    )
                  })}
                </ul>
              </InView>

              <section
                className={`${styles.projectArrows} ${styles.fadeIn}`}
                id="projectArrows"
              >
                <TransitionLink
                  className={styles.arrowLeft}
                  to={`${this.state.leftLink}`}
                  exit={{ length: 0.6 }}
                  entry={{ delay: 0.8, length: 1 }}
                >
                  <div className={styles.arrowContainer}>
                    <img src={arrowEmpty} alt="arrow empty" />
                  </div>

                  <div className={styles.arrowContainerFull}>
                    <img src={arrowFull} alt="arrow full" />
                  </div>
                </TransitionLink>

                <TransitionLink
                  className={styles.arrowRight}
                  to={`${this.state.rightLink}`}
                  exit={{ length: 0.6 }}
                  entry={{ delay: 0.8, length: 1 }}
                >
                  <div className={styles.arrowContainer}>
                    <img src={arrowEmpty} alt="arrow empty" />
                  </div>

                  <div className={styles.arrowContainerFull}>
                    <img src={arrowFull} alt="arrow full" />
                  </div>
                </TransitionLink>
              </section>
            </div>
          </Layout>
        </Transitioner>
      </div>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
         query navIndexQueryAndBlogPostBySlug($slug: String!) {
           allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
             edges {
               node {
                 title
                 slug
                 heroImage: heroImage {
                   sizes(maxWidth: 500, resizingBehavior: PAD) {
                     ...GatsbyContentfulSizes_withWebp
                   }
                   file {
                     contentType
                     url
                   }
                   title
                 }
               }
             }
           }
           contentfulBlogPost(slug: { eq: $slug }) {
             title
             publishDate(formatString: "MMMM Do, YYYY")
             heroImage: heroImage {
               sizes(maxWidth: 1000, resizingBehavior: PAD) {
                 ...GatsbyContentfulSizes_withWebp
               }
               file {
                 contentType
                 url
               }
               title
             }
             imageLeft: imageLeft {
               sizes(maxWidth: 1000, resizingBehavior: PAD) {
                 ...GatsbyContentfulSizes_withWebp
               }
               file {
                 contentType
                 url
               }
               title
             }
             imageRight: imageRight {
               sizes(maxWidth: 1000, resizingBehavior: PAD) {
                 ...GatsbyContentfulSizes_withWebp
               }
               file {
                 contentType
                 url
               }
               title
             }
            #  blogImage: blogImage {
            #    sizes(maxWidth: 1000, resizingBehavior: PAD) {
            #      ...GatsbyContentfulSizes_withWebp
            #    }
            #    file {
            #      contentType
            #      url
            #    }
            #    title
            #  }
             extraImages {
               sizes(maxWidth: 1000, resizingBehavior: PAD) {
                 ...GatsbyContentfulSizes_withWebp
               }
               file {
                 contentType
                 url
               }
               title
             }
             body {
               childMarkdownRemark {
                 html
               }
             }
           }
         }
       `
